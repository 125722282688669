import { useState, useEffect } from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { gridSettings, initialSort } from "configs/gridSettings";
import { NameValueCell } from 'components/grid/NameValueCell';
import { GuidCell } from "components/grid/GuidCell";
import { LockedGuidCell } from "components/grid/LockedGuidCell";
import { LockedNameCell } from "components/grid/LockedNameCell";
import { LockedPhotoCell } from "components/grid/LockedPhotoCell";
import useModelListProducts from 'context/modelListProductsContext';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { ActionColumns } from 'components/grid/action-column';
import { LabelCell } from 'components/grid/label-cell';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import ErrorToast from 'components/toaster/errorToastr';
import Toast from 'components/toaster/toastr';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import SWDialog from 'components/shared/dialog';
import { CopiableCell } from 'components/grid/CopiableCell';
import { RangeFilterCell } from 'components/grid/DateRangeFilterCell';
import { useSearchPagination } from 'components/hooks/useSearchPagination';

export const ModelListProductsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { isLoading, products, meta, getModelListProducts, isSuccess, error } = useModelListProducts();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState(initialSort);
  const [searchPageNumber, searchPageSize] = useSearchPagination();
  let { previewId } = useParams();

  useEffect(() => {
    const d = { ...dataState }
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, [searchPageSize, searchPageNumber]);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`)
    getModelListProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort, modelListId: previewId })
  }, [dataState])

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  }

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getModelListProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort, modelListId: previewId });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getModelListProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort })
  }

  return (<div>
    {isSuccess && <Toast message="Sucessfully added product" />}
    {error && <ErrorToast message="Error getting products" />}
    {products && meta ? <Grid
      filter={filter}
      onFilterChange={filterChange}
      sort={sort}
      onSortChange={(e: GridSortChangeEvent) => {
        setSort(e.sort);
        sortChanged(e.sort);
      }}
      {...gridSettings}
      className="k-grid-container"
      data={products}
      total={meta.total_count}
      pageSize={dataState.take}
      onDataStateChange={(e: GridDataStateChangeEvent) => {
        setDataState(e.dataState as any);
      }}
      skip={meta.page_size * meta.current_page - meta.page_size}
    >
      <GridColumn locked sortable={false} field="photo" title="Photo" width="90px" filterable={false} cell={LockedPhotoCell} />
      <GridColumn locked sortable={false} field="id" title="UUID" width="110" cell={LockedGuidCell} />
      <GridColumn locked field="name" title="Name" width="300px" cell={LockedNameCell} />
      <GridColumn field="full_name" title="Full Name" width="250" cell={CopiableCell} />
      <GridColumn field="labels" title="Labels" width="200" cell={LabelCell} />
      <GridColumn field="ean" width="200" title="EAN" sortable={false} cell={CopiableCell} />
      <GridColumn field="upc" width="200" title="UPC" sortable={false} cell={CopiableCell} />
      <GridColumn field="category" width="200" title="Category" cell={NameValueCell} />
      <GridColumn field="supplier" width="200" title="Supplier" cell={NameValueCell} />
      <GridColumn field="brand_family" width="200" title="Brand Family" cell={NameValueCell} />
      <GridColumn field="brand" width="200" title="Brand" cell={NameValueCell} />
      <GridColumn field="classification" width="200" title="Classification" cell={NameValueCell} />
      <GridColumn field="subclassification" width="200" title="Subclassification" cell={NameValueCell} />
      <GridColumn field="pack_size" title="Pack Size" width="100" />
      <GridColumn field="region" title="Region" width="100" />
      <GridColumn field="pack_type" title="Pack Type" width="100" />
      <GridColumn field="size" title="Size" width="100" />
      <GridColumn field="created_at" width="240" filterCell={RangeFilterCell} title="Created at" />
      <GridColumn field="updated_at" width="240" filterCell={RangeFilterCell} title="Updated at" />
      {process.env.REACT_APP_SHOW_METRICS == "true" && <GridColumn field="metrics" width="240" filterable={false} sortable={false} title="Accuracy" cell={o => (
        <td>
          {o.dataItem.metrics?.accuracy}
        </td>
      )} />}
      {process.env.REACT_APP_SHOW_METRICS == "true" && <GridColumn field="metrics" width="240" filterable={false} sortable={false} title="Crop Count" cell={o => (
        <td>
          {o.dataItem.metrics?.crop_count}
        </td>
      )} />}
    </Grid > :
      <FullPageLoader />
    }
  </div >);
};
