import { GridCellProps } from "@progress/kendo-react-grid";
import { NavLink } from "react-router-dom";

export const LockedNameCell = (props: GridCellProps) => {
  const { colSpan, className, style, ariaColumnIndex, field, dataItem } = props;
  
  return (
    <td
      colSpan={colSpan}
      className={className}
      style={style}
      aria-colindex={ariaColumnIndex}
      role={'gridcell'}
    >
      <NavLink to={`/products/${dataItem.id}`} replace={true} target="_blank">
        {dataItem.name}
      </NavLink>
    </td>
  );
};
