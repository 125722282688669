import { useState, useEffect } from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { gridSettings, initialSort } from "configs/gridSettings";
import { NameValueCell } from 'components/grid/NameValueCell';
import { GuidCell } from "components/grid/GuidCell";
import useProducts from 'context/productContext';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { ActionColumns } from 'components/grid/action-column';
import { LabelCell } from 'components/grid/label-cell';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import ErrorToast from 'components/toaster/errorToastr';
import Toast from 'components/toaster/toastr';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

import SWDialog from 'components/shared/dialog';
import { CopiableCell } from 'components/grid/CopiableCell';
import { RangeFilterCell } from 'components/grid/DateRangeFilterCell';

import { useSearchPagination } from 'components/hooks/useSearchPagination';
import { PermissionGate } from 'shared/functions/permissionGate';

export const ProductsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { isLoading, products, meta, getProducts, isSuccess, message, error, removeProductbyId } = useProducts();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState(initialSort);
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  useEffect(() => {
    const d = { ...dataState }
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`)
    getProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort })
  }, [dataState])

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  }

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const removeProduct = () => {
    setIsDialogOpen(false);
    removeProductbyId(deleteId);
  }

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort })
  }

  return (<div>
    <SWDialog
      open={isDialogOpen}
      text="Are you sure that you want to delete this Product?"
      confirmText="Delete"
      onConfirm={() => removeProduct()}
      onClose={() => setIsDialogOpen(false)}
    />
    {isSuccess && <Toast message={message} />}
    {error && <ErrorToast message={error} />}
    {products && meta ? <Grid
      filter={filter}
      onFilterChange={filterChange}
      navigatable={true}
      filterable={true}
      sort={sort}
      onSortChange={(e: GridSortChangeEvent) => {
        setSort(e.sort);
        sortChanged(e.sort);
      }}
      {...gridSettings}
      className="k-grid-products-container"
      data={products}
      total={meta.total_count}
      pageSize={dataState.take}
      onDataStateChange={(e: GridDataStateChangeEvent) => {
        setDataState(e.dataState as any);
      }}
      skip={meta.page_size * meta.current_page - meta.page_size}
    >
      <GridColumn sortable={false} field="photo" title="Photo" width="90px" filterable={false} cell={o => (
        <td>
          {o.dataItem.thumbnail_url ? <img src={o.dataItem.thumbnail_url} className="grid-thumbnail" />
            :
            <div style={{ "height": "36px", "width": "36px", "float": "left" }} >
              <NoPhotographyIcon></NoPhotographyIcon>
            </div>
          }
        </td>
      )} />
      <GridColumn sortable={false} field="id" title="UUID" width="180" cell={GuidCell} />
      <GridColumn field="name" title="Name" width="300px" cell={(o: GridCellProps) => (
        <td>
          <NavLink to={`/products/${o.dataItem.id}`} replace={true}>
            {o.dataItem.name}
          </NavLink>
        </td>
      )} />
      <GridColumn field="full_name" title="Full Name" width="250" cell={CopiableCell} />
      <GridColumn field="labels" title="Labels" width="200" cell={LabelCell} />
      <GridColumn field="ean" width="120" title="EAN" sortable={false} cell={CopiableCell} />
      <GridColumn field="upc" width="120" title="UPC" sortable={false} cell={CopiableCell} />
      <GridColumn field="category" width="200" title="Category" cell={NameValueCell} />
      <GridColumn field="supplier" width="200" title="Supplier" cell={NameValueCell} />
      <GridColumn field="brand_family" width="200" title="Brand Family" cell={NameValueCell} />
      <GridColumn field="brand" width="200" title="Brand" cell={NameValueCell} />
      <GridColumn field="classification" width="200" title="Classification" cell={NameValueCell} />
      <GridColumn field="subclassification" width="200" title="Subclassification" cell={NameValueCell} />
      <GridColumn field="region" title="Region" width="150" />
      <GridColumn field="pack_size" title="Pack Size" width="100" />
      <GridColumn field="pack_type" title="Pack Type" width="100" />
      <GridColumn field="size" title="Size" width="100" />
      <GridColumn field="created_at" width="240" filterCell={RangeFilterCell} title="Created at" />
      <GridColumn field="updated_at" width="240" filterCell={RangeFilterCell} title="Updated at" />
      <GridColumn field="actions" sortable={false} title="Actions" filterable={false} width="200px" cell={o => (
        <PermissionGate permission="write" resource="products">
          <ActionColumns id={o.dataItem.id} route="products" onDelete={(id: string) => {
            setDeleteId(id);
            setIsDialogOpen(true);
          }} />
        </PermissionGate>
      )} />
    </Grid > :
      <FullPageLoader />
    }
  </div >);
};
