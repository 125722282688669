import { useForm, Controller } from "react-hook-form";
import { SaveButton } from "components/shared/saveButton";
import usePredefinedClasses from "context/predefinedClassesContext";
import { FormMode } from "models/util";
import { PredefinedClass } from "models/predefined_class";
import { Autocomplete, Checkbox, CircularProgress, FormControlLabel, TextField } from "@mui/material";
import { featureFlags } from "configs/featureFlags";
import useCategories from "context/categoriesContext";
import useRegions from "context/regionsContext";
import React, { useEffect } from "react";
import { get } from "lodash";

interface Props {
  mode?: FormMode,
  pc?: PredefinedClass,
  onExit: any
}

const CreatePredefinedClass = (props: Props) => {
  const { editPredefinedClass, createPredefinedClass } = usePredefinedClasses();
  const { categories, getCategories } = useCategories();
  const { regions, getRegions } = useRegions();
  const [open, setOpen] = React.useState(false);
  const loading = open && categories.length === 0;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue
  } = useForm({
    defaultValues: {
      id: props.pc?.id,
      name: props.pc?.name,
      description: props.pc?.description,
      model_list: props.pc?.model_list,
      region: props.pc?.region?.id,
      category: props.pc?.category?.id,
    },
  });

  useEffect(() => {
    getCategories({ page: 1, items_per_page: 200 });
    getRegions({ page: 1, items_per_page: 200 });
  }, []);

  const getCategoryForName = (searchTerm: string) => {
    getCategories({ page: 1, items_per_page: 20, filters: [{ field: "name", value: searchTerm }] });
  }

  const getRegionForName = (searchTerm: string) => {
    getRegions({ page: 1, items_per_page: 20, filters: [{ field: "name", value: searchTerm }] });
  }

  const modelListChecked = watch("model_list");

  const onSubmit = (data: any) => {
    if (props.mode === FormMode.Create) {
      data.labels = [];
      createPredefinedClass(data).then((error: any) => {
        if (!error) {
          props.onExit();
        }
      });
    } else {
      editPredefinedClass(data).then((error: any) => {
        if (!error) {
          props.onExit();
        }
      });
    }
  };

  return (
    <div className="row">
      <form
        className={errors.name ? "was-validated" : "needs-validation"}
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
        noValidate
      >
        <div className="card">
          <div className="card-body">
            <label className="form-label">Name</label>
            <input
              className="form-control"
              {...register("name", { required: true })}
              required
            />
            {errors.name && (
              <div className="invalid-feedback">
                <p className="warning-icon">This field is required.</p>
              </div>
            )}
          </div>
          <div className="card-body">
            <label className="form-label">Description</label>
            <input
              className="form-control"
              {...register("description", { required: false })}
            />
          </div>
        </div>
        {featureFlags.modelLists &&
          <div className="card my-3">
            <div className="card-body">
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("model_list")}
                    defaultChecked={props.pc?.model_list || false}
                  />
                }
                label={
                  <div>
                    <span>Model List</span>
                    <br />
                    <span className="small small-bottom">
                      The "Model List" contains all products associated with a specific model within a single region and category.
                    </span>
                  </div>
                }
              />
              {modelListChecked && (
                <>
                  <div className="card-body">
                    <Autocomplete
                      onInputChange={(event, newInputValue) => {
                        getRegionForName(newInputValue);
                      }}
                      onChange={(event: any, newValue: any | null) => {
                        setValue("region", newValue?.id);
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name}
                      options={regions}
                      defaultValue={props.pc?.region}
                      loading={loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.region}
                          variant="outlined"
                          helperText={!!errors.region && "This field is required."}
                          fullWidth
                          label="Region"
                          InputProps={{
                            ...register('region', {
                              required: "This field is required."
                            }),
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="card-body">
                    <Autocomplete
                      onInputChange={(event, newInputValue) => {
                        getCategoryForName(newInputValue);
                      }}
                      onChange={(event: any, newValue: any | null) => {
                        setValue("category", newValue?.id);
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name}
                      options={categories}
                      defaultValue={props.pc?.category}
                      loading={loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.category}
                          variant="outlined"
                          helperText={!!errors.category && "This field is required."}
                          fullWidth
                          label="Category"
                          InputProps={{
                            ...register('category', {
                              required: "This field is required."
                            }),
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        }
        <div className="bottom-bar">
          <SaveButton />
        </div>
      </form>
    </div>
  );
};

export default CreatePredefinedClass;