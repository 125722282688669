import { GridCellProps } from '@progress/kendo-react-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from "@mui/material/Tooltip";

export const LockedGuidCell = (props: GridCellProps) => {
  const { colSpan, className, style, ariaColumnIndex, field, dataItem } = props;
  const onClick = () => navigator.clipboard.writeText(props.dataItem?.id)
  
  return (
    <td
      colSpan={colSpan}
      className={className}
      style={style}
      aria-colindex={ariaColumnIndex}
      role={'gridcell'}
    >
      {props.field && props.dataItem?.id?.substring(0, 8)} &nbsp;
      <Tooltip title="Copy to clipboard">
        <ContentCopyIcon onClick={onClick} className="copyButton" sx={{ fontSize: 14 }} style={{ "cursor": "pointer" }} />
      </Tooltip>
    </td>
  );
};
